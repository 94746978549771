export const DOMAIN_DEV = 'dev.keshet12vote.co.il';
export const DOMAIN_PROD = 'keshet12vote.mako.co.il';

export const IS_DEV = false; // window.location.host !== DOMAIN_PROD;

export const DOMAIN = IS_DEV ? DOMAIN_DEV : DOMAIN_PROD;
export const PUBNUB_SUBSCRIBE_KEY = IS_DEV
  ? 'sub-c-ec9aa3aa-cb5f-4552-9330-7a6c96674207'
  : 'sub-c-cf0d0005-a7f4-4dd7-bb8b-453f7644b57b';

export const PUBNUB_PUBLISH_KEY = IS_DEV
  ? 'pub-c-ecb04637-ce60-4dc4-91df-3b9d4a755336'
  : 'pub-c-5f16fee8-8d2c-4225-927e-5025cfa9c090';

export const PUBNUB_ADMIN_SIGNATURE = 'admin-e0de8cdc-fbb6-472e-a9cb-ffe6f85797ab';

export * as ROUTES from './routes';

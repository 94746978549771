import moment from 'moment';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { NavigateFunction } from 'react-router-dom';

import { IS_DEV } from '@client/constants';
import { ls } from '@client/utils/local-storage';
import { api } from '@client/redux/api/enhanced';
import { sleep } from '@client/utils/sleep';
import { linkMarkup } from '@client/utils/link-markup';
import { NativeBridge } from '@client/utils/native-bridge';
import { NOT_FOUND } from '@client/constants/routes';
import { Model as ProgramModel, RegistrationField } from '@common/models/program';
import { processSessionResult } from './helpers';
import { ProgramState } from '../types';
import { encodeIdentity } from './helpers';

type Params = { id: string; navigate: NavigateFunction };
type Result = ProgramState | null;

export const init = createAsyncThunk<Result, Params, { rejectValue: string }>(
  'program/init',
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const dataUrl = `https://assets.${IS_DEV ? 'dev.' : ''}keshet12vote.co.il/${params.id}/data.json`;
      const response = await fetch(dataUrl);

      if (response.status >= 400) {
        params.navigate(NOT_FOUND);
        throw new Error('Can not fetch program settings');
      }

      const programData = (await response.json()) as ProgramModel | null;

      if (programData) {
        const programId = programData.id;
        const programLocalData = ls.getData(programId);
        const registrationType = programData.registration?.type;
        let stage: ProgramState['stage'] = programLocalData?.stage;
        let identity: ProgramState['identity'];
        let isPristine = true;

        if (!stage && (registrationType === 'Native' || registrationType === 'FormAndNative')) {
          const identityStr = await getIdentity();

          if (identityStr) {
            identity = JSON.parse(decodeURIComponent(identityStr));
            if (identity) identity.tzoffset = moment().utcOffset();
          } else {
            // params.navigate(NATIVE_APP_ERROR);
            const currentUrl = new URL(window.location.href);
            currentUrl.pathname = '/native-error.html';
            window.location.href = currentUrl.toString();

            return null;
          }

          // In case of 'Native' registration create session right here, because there will be no
          // further submit registration action from application:
          if (registrationType === 'Native') {
            const createProgramSession = api.endpoints.CreateProgramSession.initiate({
              programId,
              register: {
                FullName: 'Unknown',
              },
              nativeHash: encodeIdentity(identity),
            });
            const createProgramSessionResult = await dispatch(createProgramSession);

            // processSessionResult() updates local storage data, so when it comes to set initial card to redux store
            // cardActions.init will deal with updated local storage
            const localProgramData = processSessionResult(programId, createProgramSessionResult);

            stage = localProgramData.stage;

            // stats:
            const viewProgram = api.endpoints.ViewProgram.initiate({ programId: programData.id });
            dispatch(viewProgram);
          }
        }

        if (registrationType === 'Native' || registrationType === 'FormAndNative') {
          try {
            NativeBridge.callWithCallback('disableScreenTimeout', console.log, 'true');
          } catch (e) {
            console.log(e);
          }
        }

        // call GetLatestActivity only after registration, in this action registration is performed automatically
        // only in case registrationType is Native (stage is a flag of registration,
        // if not undefined — registration passed):
        if (!!stage || !programData.registration?.active) {
          const getLatestActivity = api.endpoints.GetLatestActivity.initiate({ programId: programData.id });
          const getLatestActivityResult = await dispatch(getLatestActivity);

          // when there is response with data but 'getLatestActivity' field is null
          // it means no card was pushed by moderator yet, setting pristine prevents pubnub.history usage:
          isPristine = getLatestActivityResult.data?.getLatestActivity === null;

          if (!programData.registration?.active) {
            stage = 'N';
          }
        }

        applyPixels(programData.script);

        return { ...applyDefaults(programData), stage, identity, isPristine };
      } else {
        params.navigate(NOT_FOUND);
      }

      throw new Error('Can not fetch program settings');
    } catch (e) {
      console.log(e);
      return rejectWithValue((e as Error).message);
    }
  },
);

const getIdentity = async () => {
  // application should behave "like everything is ok" in case of any error with registration.
  // So we dont care much about error reason here, just resolve 'null' and continue

  const connectionPromise = new Promise<void>((resolve) => {
    try {
      NativeBridge.callWithCallback('makoResolveVariable', resolve);
    } catch (e) {
      // it's intentional:
      resolve();
    }
  });

  const identityPromise = new Promise<string | null>((resolve) => {
    try {
      NativeBridge.callWithCallback('identifyUser', resolve);
    } catch (e) {
      // it's intentional:
      resolve(null);
    }
  });

  const promises = Promise.all([connectionPromise, identityPromise]);
  const result = await Promise.race([promises, sleep(10000)]);
  const identity = result ? result[1] : null;

  return identity;
};

const applyDefaults = (programData: ProgramModel) => {
  const theme: ProgramState['theme'] = {
    logo: '',
    logoPlacement: 'default',
    bgImage: '',
    color: 'white',
    bgColor: 'black',
    primaryColor: '#BA9E57',
    primaryTextColor: '#F9D870',
    dimmedColor: 'rgba(255, 255, 255, 0.6)',
    semiDimmedColor: 'rgba(255, 255, 255, 0.8)',
    borderColor: 'rgba(255, 255, 255, 0.16)',
    errorColor: '#ED6D5A',
    errorBgColor: 'rgba(252, 135, 117, 0.15)',
    controlsBorderColor: 'white',
    btnBgColor: '',
    btnColor: '',
    ...programData.theme,
  };
  const texts: ProgramState['texts'] = {
    inouts: {
      name: 'שם מלא',
      email: 'מייל',
      phone: 'נייד',
    },
    validation: {
      invalidEmail: 'כתובת דוא"ל שגויה',
      invalidPhone: 'המספר אינו תקין',
      invalidValue: 'ערך לא תקין',
      required: 'שדה חובה',
      ...programData.texts?.validation,
    },
  };

  let registration: ProgramModel['registration'] = programData.registration;
  if (programData.registration) {
    const tosField: RegistrationField = {
      active: !!programData.tos?.active,
      name: 'TOS',
      required: !!programData.tos?.showCheckbox,
      label: linkMarkup(programData.tos?.text || '', programData.tos?.url),
    };

    registration = {
      ...programData.registration,
      fields: [...programData.registration.fields, tosField],
    };
  }

  return {
    ...programData,
    texts,
    registration,
    theme,
  };
};

const applyPixels = (script: string | undefined) => {
  if (!script) return;

  const container = document.createElement('div');
  container.innerHTML = script;

  const parsedScriptElement = container.querySelector('script');
  const scriptElement = document.createElement('script');

  scriptElement.innerHTML = parsedScriptElement ? parsedScriptElement.innerHTML : script;
  document.body.appendChild(scriptElement);
};
